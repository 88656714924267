<template>
     <div
        :id="modalBlock"
        class="modal"
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Block Jadwal</h5>
                     <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        @click="close()"
                    ></button>
                </div>
                <div class="modal-body">
                  <form >
                       <div class="form-group">
                      <label for="exampleInputPassword1">{{this.$i18n.t("formInternal.date")}}</label>
                       <VueDatePicker
                        v-model="sel.date"
                        :validation="'required'"
                        :validationName="'tanggal'"
                        :name="'tanggal'"
                       />
                      </div>
                      <div class="form-group">
                      <label for="inputPilihDoctor">{{this.$i18n.t("formInternal.select")}}</label>
                          <vue-multi-select
                          :options="doctorsName"
                          v-model="sel.doctor"
                          :name="'doktor'"
                          :validation="'required'"
                          :validationName="'Doktor'"
                          >
                          </vue-multi-select>
                      </div>
                    
                    <select-jadwal
                    :options="selectedDokter"
                    :list-reservasi="[]"
                    :list-defect="[]"
                    :date="sel.date"
                    :name="'selectJadwal'"
                    :validation="'required'"
                    :scope="'form-2'"
                    :validation-name="'Jadwal'"
                    :value="value"
                    v-model="value"
                    ></select-jadwal>
                  </form>
                </div>
                <div class="modal-footer">
                      <button type="submit" class="btn btn-primary" @click="submit">Blok</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import SlotJadwalType from "./../../../constants/slot-jadwal-type-enum";
    import { RepositoryFactory } from "./../../../repositories/RepositoryFactory";
    import { mapState, mapMutations, mapActions } from "vuex";
    import WizardContentTwo from '../../doctor-appointments/ModalReservasi/_components/WizardContentTwo';
    import vueMultiSelect from './../../_select/VueMultiSelect';
    import VueDatePicker from './../../_general/VueDatePicker';
    import SelectJadwal from './../../_select/SelectJadwal';
    import CheckPermission from "./../../../services/checkPermission.service";
    const checkPermission = new CheckPermission();
    export default {
        data(){
            return{
                sel:{
                    date: null,
                    doctor: null,
                    slot: null
                },
                value:{
                    id: null,
                    jadwal: null,
                    jamMulai: null,
                    jamSelesai: null
                },
                listDoctorName: []
            }
        },
        components: {
            WizardContentTwo,
            vueMultiSelect,
            VueDatePicker,
            SelectJadwal,
        },
        props: {
            modalBlock: { type: String, default:"modalBlock"},
        },
        computed: {
            doctorsName: function () {
                let names = []
                this.listDoctorName.forEach(el => names.push(el.nama));

                return names
            },
            selectedDokter: function(){
                let jadwal = this.listDoctorName.filter(word => word.nama == this.sel.doctor);
                return jadwal
            }
            
        },
        methods: {
            submit(){
                console.log(this.value, 'submit');
                
            },
            async getListDokters(){
              try{
                const DoctorRepository = RepositoryFactory.get("doctor");
                let doctorLogin = checkPermission.isCanAccess('DokterResource.GET.Dokter.Current')
                let listDokters
                if(doctorLogin){
                    listDokters = new Promise((resolve, reject) => {
                        DoctorRepository.getCurrentDoctors({}, resolve, reject);
                    }); 
                }else{
                    listDokters = new Promise((resolve, reject) => {
                        DoctorRepository.get({}, resolve, reject);
                    });
                }

                 const responseDokter = await Promise.all([listDokters]);
                this.listDoctorName = responseDokter[0].data
                // console.log(this.listDoctorName);

              }catch(err){
                toastr.error('error get data');
              }
            },
             ...mapMutations({
                setShowModalBlock: "DayCalendarStore/SET_SHOW_MODAL_BLOCK"
            }),
            close() {
                $("#" + this.modalBlock).modal("hide");
                this.setShowModalBlock(false);
            },
        

        },
        mounted () {
            $("#" + this.modalBlock).modal();
            this.getListDokters()
            // this.setSelectedEvent(null);
            // console.log(this.listEventWaiting);
            
        },
    }
</script>
<style lang="scss" scoped>

</style>
